import React, { useContext, useEffect } from 'react';
import "../../Styles/DataRouteForm.css";
import "../../Styles/GlobalStyle.css";
import { useState } from 'react';
import { PrimaryButton } from '../ThemeComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../Context/UserInfoContext';

function DataRouteForm() {
  const Navigate = useNavigate();
  const { Weight, setWeight, Height, setHeight,
    DoB, setDoB, DoBYear, setDoBYear, DoBDay, setDoBDay, DoBMonth, setDoBMonth,
    Recent, setRecent, setAge,
    // email,setemail,
    Gender, setGender, Active, setActive
  } = useContext(UserInfo);

  const [ClearToGo,setClearToGo] = useState(false)

  const months = {
    "Enero": 1,
    "Febrero": 2,
    "Marzo": 3,
    "Abril": 4,
    "Mayo": 5,
    "Junio": 6,
    "Julio": 7,
    "Agosto": 8,
    "Septiembre": 9,
    "Octubre": 10,
    "Noviembre": 11,
    "Diciembre": 12
  };
  function generateLast150Years() {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= currentYear - 149; i--) {
      years.push(i);
    }

    return years;
  }

  const monthdays = {

    1: 31,
    2: (new Date(DoBYear, 1, 29)).getDate() === 29 ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  const [validation, setvalidation] = useState({
    "Gender": "valid",
    "DoB": "valid",
    "Weight": "valid",
    "Height": "valid",
  });

  const handleChange = (value, setValue) => {
    const result = value.replace(/\D/g, '');

    setValue(result.slice(0, 3));
  };

  const handleGender = (ActiveKey) => {
    setvalidation(prevState => ({
      ...prevState, // Spread the previous state
      "Gender": Object.values(Active).every(value => value === "GenderTile") ? "valid" : "invalid"
    }));
    for (const key in Active) {
      setActive((State) => ({
        ...State,
        [key]: "GenderTile",
      }))
    }
    let tmp1I = document.getElementById("MaleTileIcon")
    let tmp2I = document.getElementById("FemaleTileIcon")

    tmp1I.className = tmp1I.className.replace(" HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon","")
    tmp2I.className = tmp2I.className.replace(" HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon","")

    setGender("")

    if (Active[ActiveKey] === "GenderTile") {
      setActive((State) => ({
        ...State,
        [ActiveKey]: "GenderTileActive",
      }))
      if(ActiveKey === "male") {
        tmp1I.className += " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender(1)
      } else if(ActiveKey === "female") {
        tmp2I.className += " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender(2)
      }
      setvalidation(prevState => ({
        ...prevState, // Spread the previous state
        "Gender": "valid"
      }));

    }


  }

  function AgeValidity() {
    const currentDate = new Date();

    const age = currentDate.getFullYear() - DoB.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    if (age > 15) {
      if (currentMonth > DoBMonth-1 || (currentMonth === DoBMonth-1 && currentDay >= DoBDay)) {
        setAge(age)
        return true;
      } else {
        setAge(age-1)
        return true;
      }
    } else if (age === 15) {

      if (currentMonth > DoBMonth-1 || (currentMonth === DoBMonth-1 && currentDay >= DoBDay)) {
        setAge(age)
        return true;
      }
    }

    return false;
  }

  function HandleSubmit() {
    const isDoBValid = AgeValidity() ? "valid" : "invalid";
    const isWeightValid = Weight.trim() === "" ? "invalid" : "valid";
    const isHeightValid = Height.trim() === "" ? "invalid" : "valid";
    const isGenderValid = Object.values(Active).every(value => value === "GenderTile") ? "invalid" : "valid";

    setvalidation({
      "DoB": isDoBValid,
      "Weight": isWeightValid,
      "Height": isHeightValid,
      "Gender": isGenderValid,
    });

    if (
      isGenderValid === "valid" &&
      isDoBValid === "valid" &&
      isWeightValid === "valid" &&
      isHeightValid === "valid"
    ) {
      document.body.scrollTop = 0; 
      document.documentElement.scrollTop = 0; 
      setClearToGo(true)
    }
  }


  useEffect(() => {
    setDoB(new Date(DoBYear, DoBMonth - 1, DoBDay))
  }, [DoBMonth, DoBDay, DoBYear,setDoB]);

  useEffect(() => {
    if(ClearToGo) {
      Navigate("/recomendador/paso-2");
    }
  },[ClearToGo,Navigate]);


  return (
    <div className="DataRouteFormDiv">
      <div className='DataRouteGenderDateFlex'>
        <div className='DataRouteGenderDiv'>
          <div className="GenderTitle">
          Sexo al nacer
          </div>
          <div className='GenderTilesFlexed'>
            <div className={"GenderMale rounded-2 " + (Gender === 1 ? "GenderTileActive" : "GenderTile")} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("male")}>
              Hombre <br/>
              <img alt="" className={"HeartAgeCalculatorRightSideSurveyQ1TileIcon" + (Gender === 1 ? " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" : "")} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png" id="MaleTileIcon"/>
            </div>
            <div className={"GenderFemale rounded-2 " + (Gender === 2 ? "GenderTileActive" : "GenderTile")} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("female")}>
              Mujer <br/>
              <img alt="" className={"HeartAgeCalculatorRightSideSurveyQ1TileIcon" + (Gender === 2 ? " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" : "")} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleFemaleIconT.png" id="FemaleTileIcon"/>
            </div>
          </div>
          {/* <div className={"GenderNB rounded-2 " + Active["NB"]} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("NB")}>
            <FontAwesomeIcon icon={faMarsAndVenus} /> No binario
          </div> */}
          {validation["Gender"] === "invalid" ?
            <div className='GenderAlert' style={{ color: "red", visibility: validation["Gender"] === "invalid" ? "visible" : "collapse", fontWeight: "500" }}> Por favor seleccione su sexo al nacer</div>
          : null}
        </div>

        <div className='DataRouteDateDiv'>
          <div className="DoBTitle">
            Fecha de Nacimiento

          </div>
          <div className='DateTilesFlexed'>
            <div className="DoBDay">
              <select defaultValue={DoBDay} className='DoBTile rounded-2' style={{ border: validation["DoB"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} id="DaySelectDataRoute" onChange={(e) => setDoBDay(parseInt(e.target.value))}>
                {Array.from({ length: monthdays[DoBMonth] }, (_, i) => i + 1).map((day) => (
                  <option key={day} value={parseInt(day)}>{day}</option>
                ))}

              </select>
            </div>
            <div className="DoBMonth">
              <select defaultValue={DoBMonth} className='DoBTile rounded-2' style={{ border: validation["DoB"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} id="MonthSelectDataRoute" onChange={(e) => setDoBMonth(parseInt(e.target.value))}>
                {Object.keys(months).map((month) => (
                  <option key={months[month]} value={months[month]}>{month}</option>
                ))}
              </select>
            </div>
            <div className="DoBYear">
              <select defaultValue={DoBYear} className='DoBTile rounded-2' style={{ border: validation["DoB"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} id="YearSelectDataRoute" onChange={(e) => setDoBYear(e.target.value)}>
                {generateLast150Years().map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>
          {validation["DoB"] === "invalid" ?
            <div className='DoBAlert' style={{ color: "red", fontWeight: "500" }}> Debe tener 15 años o más de edad </div>
            : null }
        </div>
      </div>

      <div className='DataRouteWeightHeightFlex'>
        <div className='DataRouteWeightDiv'>
          <div className="WeightTitle">
            Indícanos tu peso (Kg)
          </div>
          <div className="WeightInput">
            <input
              value={Weight}
              className="InputTile rounded-1"
              style={{ border: validation["Weight"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
              id="YMCWeightDataRoute"
              onChange={(e) => handleChange(e.target.value, setWeight)}
              placeholder="Ingresa tu peso en Kg" />
          </div>
          {validation["Weight"] === "invalid" ?
            <div className='WeightAlert' style={{ color: "red", fontWeight: "500" }}> Por favor indique su peso </div>
          : null }
        </div>

        <div className='DataRouteHeightDiv'>
          <div className="HeightTitle">
            {"¿Cuánto mides? (cm)"}
          </div>
          <div className="HeightInput">
            <input
              value={Height}
              className="InputTile rounded-1"
              style={{ border: validation["Height"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
              id="YMCHeightDataRoute"
              onChange={(e) => handleChange(e.target.value, setHeight)}
              placeholder="Ingresa tu altura en cm" />
          </div>
          {validation["Height"] === "invalid" ?
            <div className='HeightAlert' style={{ color: "red", fontWeight: "500" }}> Por favor indique su altura </div>
          : null}
        </div>
      </div>
      
      <div className='DataRouteLastTimeDiv'>
        <div className='DataRouteLastTime'>
          <div className='DataRouteLastTimeText'>¿Cuando fue la última vez que te hiciste un chequeo preventivo?</div>
          <div>
            <select defaultValue="<1Año" className='DoBTile rounded-2' style={{ border: "#cff9ff solid 1px" }} id="RecentSelectDataRoute" onChange={(e) => setRecent(e.target.value)}>
                <option key={"Never"} value={"Never"}>Nunca</option>
                <option key={">1Año"} value={">1Año"}>&gt; 1 Año</option>
                <option key={"<1Año"} value={"<1Año"}>&lt; 1 Año</option>
            </select>
          </div>
        </div>
        {Recent !== "<1Año" ?
          <div className='DataRouteLastTimeEncourageMessage'>Un chequeo preventivo te permitirá conocer tu estado de salud para evitar aparición de enfermedades y abordaje temprano de posibles síntomas.<br/>¡Déjanos guiarte!</div>
        : null }
      </div>

      <PrimaryButton ClassName={"DataRouteNextButton rounded-2 text-center"} label={"Siguiente"} onClick={() => HandleSubmit()} />
      {/* PadLeft="" PadRight="" PadTop="" PadBottom="" */}

    </div>
  );
}

export default DataRouteForm;