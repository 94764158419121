import React, { useState, useEffect, useContext, useCallback } from 'react';
import "./NewDesign/Styles/NavBar.css";
import { useNavigate } from 'react-router-dom';
import { Packs } from './NewDesign/Components/Context/PacksContext';
import { MedicalContext } from './NewDesign/Components/Context/MedicalContext';

function NavBar() {
  const Navigate = useNavigate();

  const [OrderChange, setOrderChange] = useState(true);

  const {
    BasketPacks, ExamsPerProduct, Purchased, SetPurchased, SetAdded
  } = useContext(Packs);

  const {
    RelatedExams
  } = useContext(MedicalContext);

  let plusOne = RelatedExams.length !== 0 ? 1 : 0

  const PricingOfBasket = useCallback(() => {
    let payment = 0
      for(let i = 0; i<BasketPacks.length; i++) {
        payment += ExamsPerProduct[BasketPacks[i]].price
      }
    
      if(RelatedExams.length !== 0) {
        payment += 2990
      }
      
      return payment
  },[BasketPacks,ExamsPerProduct,RelatedExams])

  const CurrentBasket = BasketPacks.length + plusOne;
  const [LowerBlock, setLowerBlock] = useState(window.innerWidth <= 950 ? true : false);
  const [BurgerMenu, setBurgerMenu] = useState(false);
  const [numberOfBlocks, setNumberOfBlocks] = useState(BasketPacks.length);
  const [PriceTotal, setPriceTotal] = useState(PricingOfBasket());
  const [BurgerSpecific, setBurgerSpecific] = useState(window.innerWidth <= 950 ? true : false);

  useEffect(() => {
    if(BasketPacks.length + plusOne !== CurrentBasket && OrderChange && Purchased && !document.URL.includes("orden-medica") && !document.URL.includes("mi-orden-medica")) {
      SetPurchased(false)
      setOrderChange(false)
      SetAdded(false)
    }
  },[BasketPacks, CurrentBasket,OrderChange,Purchased,SetPurchased,plusOne,SetAdded])

  useEffect(() => {
    const faceArray = ["https://static.yomecontrolo.cl/public/icons/ymc-loading.gif"]
    //preloading image
    faceArray.forEach((face) => {
      const img = new Image();
      img.src = face;
    });
  }, []);

  useEffect(() => {
    if(numberOfBlocks !== BasketPacks.length ) {
      setPriceTotal(PricingOfBasket())
      setNumberOfBlocks(BasketPacks.length)
    }
  },[numberOfBlocks,BasketPacks,Navigate,PricingOfBasket]);

  function ToggleNavbarDisplay() {
    let x = document.getElementById("NavbarBurgerIcon")
    x.classList.toggle("change");
    if(BurgerMenu) {
      setBurgerMenu(false)
    } else {
      setBurgerMenu(true)
    }
  }

  const HandleLowerBlock = useCallback(() => {
    if(window.innerWidth <= 950) {
      setLowerBlock(true)
      setBurgerSpecific(true)
    } else {
      setLowerBlock(false)
      setBurgerSpecific(false)
      setBurgerMenu(false)
    }
  },[])

  const hideUpperBar = useCallback(() => {
    let currentScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (!currentScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-30px";
    }
  },[])

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,".");
    return parts.join(",");
  }

  useEffect(() => {
    window.addEventListener("resize", HandleLowerBlock);
    return () => window.removeEventListener("resize", HandleLowerBlock);
  }, [HandleLowerBlock]);

  useEffect(() => {
    window.addEventListener("scroll", hideUpperBar);
    return () => window.removeEventListener("scroll", hideUpperBar);
  }, [hideUpperBar]);

  return (
    <div className='NavbarFull' id="navbar">
      <div className='NavbarPurpleUpBar'>
        <div className='NavbarPurpleUpBarInner'>
          <div className='NavbarPurpleUpBarInnerButton'>Blog</div>
        </div>
      </div>
      <div className="NavbarFullBackGradient">
      <div className={LowerBlock && BurgerMenu ? "NavbarFullInner NavbarMenuOpen" : "NavbarFullInner"}>
        <div className="NavbarFullInnerLeftSide">
          <img src="https://static.yomecontrolo.cl/public/icons/ymc_NavbarLogo_Vector.png" className="NavbarFullInnerLeftSideLogoYMC" alt="logo" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/")}} />
        </div>
        <div className="NavbarFullInnerMiddleButtons">
          <div className='NavbarFullInnerMiddleIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/")}}>Inicio</div>
          <div className='NavbarFullInnerMiddleIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/packs-de-examenes")}}>Exámenes</div>
          <div className='NavbarFullInnerMiddleIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/calculadora-de-salud")}}>Calculadora De Salud</div>
          <div className='NavbarFullInnerMiddleIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/quienes-somos")}}>Nosotros</div>
          {/*<div className='NavbarFullInnerMiddleIndividualButton' onClick={() => {setBurgerMenu(false); Navigate("/contacto?scroll=FAQ")}}>Preguntas Frecuentes</div>*/}
          <div className='NavbarFullInnerMiddleIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; setBurgerMenu(false); Navigate("/contacto")}}>Contacto</div>
        </div>
        <div className="NavbarFullInnerRightSide">
          <div className="NavbarFullInnerRightSideMedOrderButton" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/orden-medica")}}> 
            <span className="NavbarBeginSession">Orden Médica</span> 
            <span className="NavbarBeginSessionPrice">${numberWithCommas(PriceTotal)}</span> 
            <img src="https://static.yomecontrolo.cl/public/icons/WhiteExamIcon.png" className="NavbarFullInnerRightSideExamIcon" alt="exam" />
            <img src="https://static.yomecontrolo.cl/public/icons/WhiteCartIcon.png" className="NavbarFullInnerRightSideCartIcon" alt="cart" />
            {BasketPacks.length + plusOne === 0 ?
              null :
              <div className="NavbarFullInnerRightSideMedOrderNotification rounded-pill">{BasketPacks.length + plusOne}</div>
            }
          </div>
          {BurgerSpecific ?
          <div className="NavbarFullInnerRightSideBurgerIcon" id="NavbarBurgerIcon" onClick={() => ToggleNavbarDisplay()}>
            <div className="NavbarBurgerIconBar3"></div>
            <div className="NavbarBurgerIconBar1"></div>
            <div className="NavbarBurgerIconBar2"></div>
          </div>
          : null}
        </div>
      </div>
      {LowerBlock && BurgerMenu ?
      <div className="NavbarLowerBlockOptions">
        <div className="NavbarLowerBlockOptionsWhiteBox">
          {!document.URL.includes("encuesta-bienvenida") ?
          <>
          <div className='NavbarLowerBlockOptionsIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/")}}>Inicio</div>
          <div className='NavbarLowerBlockOptionsIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/packs-de-examenes")}}>Exámenes</div>
          <div className='NavbarLowerBlockOptionsIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/calculadora-de-salud")}}>Calculadora De Salud</div>
          <div className='NavbarLowerBlockOptionsIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; Navigate("/quienes-somos")}}>Nosotros</div>
          {/*<div className='NavbarLowerBlockOptionsIndividualButton' onClick={() => {setBurgerMenu(false); Navigate("/contacto?scroll=FAQ")}}>Preguntas Frecuentes</div> */}
          <div className='NavbarLowerBlockOptionsIndividualButton' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; setBurgerMenu(false); Navigate("/contacto")}}>Contacto</div>
          </>
          : null}
          {/* 
          <div className='NavbarLowerBlockOptionsIndividualButton2' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0; setBurgerMenu(false); Navigate("/")}}>
            <div className='NavbarLowerBlockOptionsIndividualButton2Text'>Portal Paciente &ensp;</div>
            <img className="NavbarLowerBlockOptionsIndividualButton2Image" src="https://static.yomecontrolo.cl/public/icons/PurpleProfileLaptopIcon.png" alt="profileBurgerMenu" />
          </div>*/}
        </div>
      </div>
      : null }
      </div>
    </div>
  );
}

export default NavBar;