import React from 'react';
import YMCInstructions1 from "../../Photos/LandingInstruction1.png"
import YMCInstructions2 from "../../Photos/LandingInstruction2.png"
import YMCInstructions3 from "../../Photos/LandingInstruction3.png"
import { useNavigate } from 'react-router-dom';

const MakeItEasy = () => {
    const Navigate = useNavigate();
    return (
      <div className='LandingMedicOrderMakeItEasy'>
        <div className='LandingMedicOrderMakeItEasyinnerDiv'>
          <div className='LandingMedicOrderMakeItEasyTitle'>Haz tu control, hazlo fácil</div>
          <div className='LandingMedicOrderMakeItEasyInstructionsFlex'>
            <div className='LandingMedicOrderMakeItEasyInstructionsBlock'>
              <img alt="" className='LandingMedicOrderMakeItEasyInstructionsImage' src={YMCInstructions1}/>
              <div className='LandingMedicOrderMakeItEasyInstructionsTitle'>Compra tu orden de exámen</div>
              <div className='LandingMedicOrderMakeItEasyInstructionsSubtitle'>Compra la orden de examen con aquellos que necesitas</div>
            </div>
            <div className='LandingMedicOrderMakeItEasyInstructionsBlock'>
              <img alt="" className='LandingMedicOrderMakeItEasyInstructionsImage' src={YMCInstructions2}/>
              <div className='LandingMedicOrderMakeItEasyInstructionsTitle'>Descárgala</div>
              <div className='LandingMedicOrderMakeItEasyInstructionsSubtitle'>Al pagar recibirás tu orden médica para descargar</div>
            </div>
            <div className='LandingMedicOrderMakeItEasyInstructionsBlock'>
              <img alt="" className='LandingMedicOrderMakeItEasyInstructionsImage' src={YMCInstructions3}/>
              <div className='LandingMedicOrderMakeItEasyInstructionsTitle'>Uso en laboratorio</div>
              <div className='LandingMedicOrderMakeItEasyInstructionsSubtitle'>Utilízala en cualquier laboratorio del país</div>
            </div>
          </div>
          <div className='LandingMedicOrderMakeItEasyFoot' onClick={() => {Navigate("/packs-de-examenes"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>¿No sabes donde comenzar?</div>
        </div>
      </div>
    );
};

export default MakeItEasy;
