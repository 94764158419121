import "@fontsource/montserrat";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./OldDesign/reportWebVitals";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./OldDesign/i18n";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactGA from "react-ga";
import { GoogleOAuthProvider } from '@react-oauth/google';
import DataRoute from "./NewDesign/DataRoute";
import MedicalHistoryRoute from "./NewDesign/MedicalHistoryRoute";
import FamilyRoute from "./NewDesign/FamilyRoute";
import Landing from "./NewDesign/Landing";
import ExamRecommendationRoute from "./NewDesign/ExamRecommendationRoute";
import OrderConfirmedRoute from "./NewDesign/OrderConfirmedRoute";
import UserInfoProvider from "./NewDesign/Components/Context/UserInfoContext";
import PacksProvider from "./NewDesign/Components/Context/PacksContext";
import MedicalContextProvider from "./NewDesign/Components/Context/MedicalContext";
//import LabSearcherRoute from "./NewDesign/LabSearcher";
//import IndicatorsRoute from "./NewDesign/IndicatorsRoute";
//import AddLabResultRoute from "./NewDesign/AddLabResultRoute";
//import AdminPatientsRoute from "./NewDesign/AdminPatientsRoute";
import ExamChoiceByGenderRoute from "./NewDesign/ExamChoiceByGenderRoute";
import ExamChoiceBySponsorRoute from "./NewDesign/ExamChoiceBySponsorRoute";
import ExamChoiceByRecomRoute from "./NewDesign/ExamChoiceByRecomRoute";
import WeAreYMCRoute from "./NewDesign/WeAreYMCRoute";
import ExamsByPacksRoute from "./NewDesign/ExamsByPacksRoute";
import RemakeOrderRoute from "./NewDesign/RemakeOrderRoute";
import ContactRoute from "./NewDesign/ContactRoute";
import HeartAgeCalculatorRoute from "./NewDesign/HeartAgeCalculatorRoute";
import DiabetesRiskCalculatorRoute from "./NewDesign/DiabetesRiskCalculatorRoute";
//import HealthFactorsRoute from "./NewDesign/HealthFactorsRoute";
//import BlogNewsRoute from "./NewDesign/BlogNewsRoute";
//import EnterprisesRoute from "./NewDesign/EnterprisesRoute";
import HealthCalculatorRoute from "./NewDesign/HealthCalculatorRoute";
//import AbsPerimeterCalculatorRoute from "./NewDesign/AbsPerimeterCalculatorRoute";

const TRACKING_ID = "G-5TSZG25VY0"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const Router = createBrowserRouter([
  {
    path: "/recomendador/paso-1",
    element: (
      <>
      <title>YMC | Recomendador</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
      <DataRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/recomendador/paso-2",
    element: (
      <>
      <title>YMC | Recomendador</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
      <MedicalHistoryRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/recomendador/paso-3",
    element: (
      <>
      <title>YMC | Recomendador</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
      <FamilyRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/recomendador/paso-4",
    element: (
      <>
      <title>YMC | Recomendador</title>
      <PacksProvider>
      <UserInfoProvider>
      <ExamChoiceByRecomRoute />
      </UserInfoProvider>
      </PacksProvider>
      </>
    ),
  },
  {
    path: "/orden-medica",
    element: (
      <>
      <title>YMC | Orden Médica</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
      <ExamRecommendationRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/mi-orden-medica",
    element: (
      <>
      <title>YMC | Mi Orden Médica</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <OrderConfirmedRoute />
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/",
    element: (
      <>
      <title>Yo Me Controlo</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
      <Landing />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  //{
  //  path: "/LabSearcherRoute",
  //  element: (
  //    <MedicalContextProvider>
  //    <PacksProvider>
  //    <LabSearcherRoute />
  //    </PacksProvider>
  //    </MedicalContextProvider>
  //  ),
  //},

  /*{
    path: "/IndicatorsRoute",
    element: (
      <MedicalContextProvider>
      <IndicatorsRoute/>
      </MedicalContextProvider>
    )
  },
  {
    path: "/AddLabResultRoute",
    element: (
      <MedicalContextProvider>
      <AddLabResultRoute/>
      </MedicalContextProvider>
    )
  },
  {
    path: "/AdminPatientsRoute",
    element: (
      <MedicalContextProvider>
      <AdminPatientsRoute/>
      </MedicalContextProvider>
    )
  }, */
  {
    path: "/detalles-de-pack",
    element: (
      <>
      <title>YMC | Detalles de Pack</title>
      <MedicalContextProvider>
      <PacksProvider>
      <UserInfoProvider>
      <ExamChoiceByGenderRoute/>
      </UserInfoProvider>
      </PacksProvider>
      </MedicalContextProvider>
      </>
    )
  },
  {
    path: "/pack-patrocinado",
    element: (
      <>
      <title>YMC | Pack Patrocinado</title>
      <MedicalContextProvider>
      <PacksProvider>
      <UserInfoProvider>
      <ExamChoiceBySponsorRoute/>
      </UserInfoProvider>
      </PacksProvider>
      </MedicalContextProvider>
      </>
    )
  },
  {
    path: "/quienes-somos",
    element: (
      <>
      <title>YMC | Quiénes Somos</title>
      <MedicalContextProvider>
      <PacksProvider>
      <UserInfoProvider>
      <WeAreYMCRoute />
      </UserInfoProvider>
      </PacksProvider>
      </MedicalContextProvider>
      </>
    ),
  },
  {
    path: "/packs-de-examenes",
    element: (
      <>
      <title>YMC | Packs de Exámenes</title>
      <MedicalContextProvider>
      <PacksProvider>
      <UserInfoProvider>
      <ExamsByPacksRoute />
      </UserInfoProvider>
      </PacksProvider>
      </MedicalContextProvider>
      </>
    ),
  },
  {
    path: "/recuperar-orden-medica",
    element: (
      <>
      <title>YMC | Recuperar Orden Médica</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
        <RemakeOrderRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/contacto",
    element: (
      <>
      <title>YMC | Contacto</title>
      <UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
        <ContactRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </UserInfoProvider>
      </>
    ),
  },
  {
    path: "/edad-del-corazon",
    element: (
      <>
      <title>YMC | Edad del Corazón</title>
      <UserInfoProvider></UserInfoProvider>
      <MedicalContextProvider>
      <PacksProvider>
        <HeartAgeCalculatorRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </>
    ),
  }, 
  {
    path: "/riesgo-diabetes",
    element: (
      <>
      <title>YMC | Riesgo de Diabetes</title>
      <MedicalContextProvider>
      <PacksProvider>
        <DiabetesRiskCalculatorRoute/>
      </PacksProvider>
      </MedicalContextProvider>
      </>
    ),
  },
  {
    path: "/calculadora-de-salud",
    element: (
      <>
      <title>YMC | Calculadora de Salud</title>
      <MedicalContextProvider>
      <PacksProvider>
        <HealthCalculatorRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </>
    ),
  },{/*
  {
    path: "/perimetro-abdominal",
    element: (
      <>
      <title>YMC | Perímetro Abdominal</title>
      <MedicalContextProvider>
      <PacksProvider>
        <AbsPerimeterCalculatorRoute />
      </PacksProvider>
      </MedicalContextProvider>
      </>
    ),
  },
*/}
 
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="373324863794-24umjtie522qdma9b4kro9lg8q7804nj.apps.googleusercontent.com">
<RouterProvider router={Router} />
</GoogleOAuthProvider>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
