import React from 'react';
import "../../Styles/WeAreYMC.css";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpLong } from "@fortawesome/free-solid-svg-icons";


function WeAreYMC() {
  const Navigate = useNavigate();

  window.onscroll = function() {
    if(document.URL.includes("quienes-somos")) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }

  return (
    <div className="WeAreYMCDiv">
        <div className='WeAreYMCFirstBlockDiv'>
            <div className='WeAreYMCFirstBlockInnerDiv'>
                <div className='WeAreYMCFirstBlockLeft'>
                    <div className='WeAreYMCFirstBlockLeftUpTitle'>Somos yomecontrolo</div>
                    <h1 className='WeAreYMCFirstBlockLeftTitle'>Queremos que la gente viva más y mejor cuidando de su salud preventivamente.</h1>
                    <h5 className='WeAreYMCFirstBlockLeftSubtitle'>Te invitamos a que estes al día con tu salud y acceder así con mayor facilidad a un diagnóstico precoz y un tratamiento oportuno de posibles patologías.</h5>
                </div>
                <div className='WeAreYMCFirstBlockRight'>
                    <img alt="" className="WeAreYMCFirstBlockRightLogo" src='https://static.yomecontrolo.cl/public/img/home/WeAreYMCFirstBlockImage.png'/>
                </div>
            </div>
        </div>
        <div className='WeAreYMCFirstBlockTrustedLogosDiv' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundFaintSkyblueOnTheLeft.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
            <div className='WeAreYMCFirstBlockTrustedLogosTitle'>Ellos han confiado en nosotros</div>
            <div className='WeAreYMCFirstBlockTrustedLogosFlex'>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"13vh"}}>
                    <img alt="Logo de Start-Up Chile" className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/STARTUP_LOGO.png"/>
                    <div className='WeAreYMCFirstBlockTrustedLogosText'>Ganadores</div>
                </div>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"12vh"}}>
                    <img alt="Logo de KnowHub" className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/KNOWHUB_LOGO.png"/>
                </div>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"7vh"}}>
                    <img alt="Logo de Desafío Avante" className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/AVANTE_LOGO.png"/>
                    <div className='WeAreYMCFirstBlockTrustedLogosText'>Ganadores</div>
                </div>
                <div className='WeAreYMCFirstBlockTrustedLogosBlock' style={{height:"5vh"}}>
                    <img alt="Logo de YLAI" className="WeAreYMCFirstBlockTrustedLogosImage" src="https://static.yomecontrolo.cl/public/img/home/YLAI_LOGO.png"/>
                    <div className='WeAreYMCFirstBlockTrustedLogosText'>Ganadores</div>
                </div>
            </div>
            <div className='WeAreYMCProblemFlex'>
                <div className='WeAreYMCFirstBlockInnerDiv'>
                    <div className='WeAreYMCProblemLeftBlock'>
                        <img alt="" className="WeAreYMCProblemLeftBlockImage" src="https://static.yomecontrolo.cl/public/img/home/WeAreYMCProblemBlock.png"/>
                    </div>
                    <div className='WeAreYMCProblemRightBlock'>
                        <div className='WeAreYMCProblemRightBlockUpTitle'>Existe un problema</div>
                        <h1 className='WeAreYMCProblemRightBlockTitle'>Hay 11 millones de chilenos con enfermedades crónicas y más de la mitad no lo sabe</h1>
                        <h5 className='WeAreYMCProblemRightBlockSubtitle'>Te podemos ayudar a identificar los factores de riesgo dentro de tu salud para encontrar ayuda a tiempo para cuidar tu bienestar.</h5>
                        <div className='GeneralPurpleButtonFlex WeAreYMCFirstBlockLeftButtonLeft' onClick={() => {Navigate("/packs-de-examenes"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Evalúa Tu Salud</div>
                    </div>
                </div>
            </div>
        </div>
        <div className='WeAreYMCOurMissionFlex'>
            <div className='WeAreYMCFirstBlockInnerDiv'>
                <div className='WeAreYMCOurMissionLeftBlock'>
                    <img alt="" className="WeAreYMCOurMissionLeftBlockImage" src="https://static.yomecontrolo.cl/public/img/home/WeAreYMCSustainableDevelopment.png"/>
                </div>
                <div className='WeAreYMCOurMissionRightBlock'>
                    <div className='WeAreYMCOurMissionRightBlockUpTitle'>Nuestra Misión</div>
                    <h1 className='WeAreYMCOurMissionRightBlockTitle'>Encontrar el bienestar presente y futuro de la salud personal / familiar con medicina preventiva a tiempo</h1>
                    <h5 className='WeAreYMCOurMissionRightBlockSubtitle'>Creemos que la tecnología debe estar al servicio de la salud, facilitando diagnósticos oportunos y un tratamiento eficaz.</h5>
                    <div className='WeAreYMCOurMissionRightBlockSmallerSubtitle'>Nuestro propósito es desarrollar herramientas digitales que empoderen a las personas a monitorear y gestionar su salud de manera preventiva.</div>
                </div>
            </div>
        </div>
        <div className='WeAreYMCSolutionsFlex' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundExtraLongWhiteAndSkyblue.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
            <div className='WeAreYMCFirstBlockInnerDiv'>
                <div className='WeAreYMCSolutionsLeftBlock'>
                    <div className='WeAreYMCSolutionsLeftBlockFirstBlock'>
                        <div className='WeAreYMCSolutionsLeftBlockFirstBlockUpTitle'>Conoce Nuestras Soluciones</div>
                        <h1 className='WeAreYMCSolutionsLeftBlockFirstBlockTitle'>Herramientas digitales que te impulsen a cuidar tu salud de manera preventiva y eficiente.</h1>
                        <div className='WeAreYMCSolutionsLeftBlockFirstBlockSubtitle'>Centradas en los pacientes permitirte evaluar el riesgo individual de desarrollar enfermedades cardíacas o  en el futuro mediante la medición de factores claves permitirá reducir el riesgo presente y futuro.</div>
                    </div>
                    <div className='WeAreYMCSolutionsLeftBlockSecondBlock'>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockUpTitle'>Ahorra tiempo</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockTitle'>Órdenes de exámenes online</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockSubtitle'>Ahorra tiempo para acudir a tu cuidado descargando tu orden de exámenes médicos de forma online</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlex'>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexText' onClick={() => {Navigate("/orden-medica"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Ver pack de exámenes &gt;&gt;</div>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImageContainer'>
                                <img alt="" className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImage' src="https://static.yomecontrolo.cl/public/img/home/WeAreYMCLungs.png"/>
                            </div>
                        </div>
                    </div>
                    <div className='WeAreYMCSolutionsLeftBlockThirdBlock'>
                        <div className='WeAreYMCSolutionsLeftBlockThirdBlockUpTitle'>Ahorra tiempo</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockTitle' style={{color:"white"}}>Portal de Pacientes</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockSubtitle' style={{color:"white"}}>Tu salud, tus indicadores, exámenes, interconsultas, estadísticas y más en un solo lugar.</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlex'>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexText WeAreYMCSolutionsLeftBlockSecondBlockFlexTextWhite' style={{cursor:"default"}}>Próximamente</div>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImageContainer'>
                                <img alt="" className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImage' src="https://static.yomecontrolo.cl/public/img/home/WeAreYMCLungs.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='WeAreYMCSolutionsRightBlock'>
                    <div className='WeAreYMCSolutionsRightBlockFirstBlock'>
                        <img alt="" className="WeAreYMCSolutionsRightBlockFirstBlockImage" src="https://static.yomecontrolo.cl/public/img/home/WeAreYMCDoctor.png"/>
                    </div>
                    <div className='WeAreYMCSolutionsRightBlockSecondBlock'>
                        <div className='WeAreYMCSolutionsRightBlockSecondBlockUpTitle'>Evalúa tu salud online</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockTitle'>Calculadora de Salud</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockSubtitle'>Podemos acercarte a un perfilamiento oportuno que identifique riesgos como tu riesgo cardiovascular o  diabetes</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlex'>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexText2' onClick={() => {Navigate("/calculadora-de-salud"); document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>Ver más &gt;&gt;</div>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImageContainer'>
                                <img alt="" className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImage' src="https://static.yomecontrolo.cl/public/img/home/WeAreYMCHealthCalculator.png"/>
                            </div>
                        </div>
                    </div>
                    <div className='WeAreYMCSolutionsRightBlockThirdBlock'>
                        <div className='WeAreYMCSolutionsRightBlockThirdBlockUpTitle'>Ahorra tiempo</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockTitle'>Ruta del Bienestar</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockSubtitle'>La mejor forma de abordar tu salud es con una planificación que aborde tu bienestar en múltiples factores, encuentra el camino a tu salud.</div>
                        <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlex'>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexText' style={{cursor:"default"}}>Próximamente</div>
                            <div className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImageContainer'>
                                <img alt="" className='WeAreYMCSolutionsLeftBlockSecondBlockFlexImage2' src="https://static.yomecontrolo.cl/public/B2B/ProfileWelcomeImage4Vect.svg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='WeAreYMCYouCanTrustFlex'>
            <div className='WeAreYMCFirstBlockInnerDiv'>
                <div className='WeAreYMCYouCanTrustLeftBlock'>
                    <div className='WeAreYMCYouCanTrustLeftBlockUpTitle'>Puedes confiar</div>
                    <h1 className='WeAreYMCYouCanTrustLeftBlockTitle'>6 Razones para tu tranquilidad con yomecontrolo</h1>
                    <div className='WeAreYMCYouCanTrustLeftBlockFlex'>
                        <div className='WeAreYMCYouCanTrustBlock'>
                            <div className='WeAreYMCYouCanTrustBlockImageContainer'>
                                <img alt="Tiny Checkbox Icon" className='WeAreYMCYouCanTrustBlockImage' src="https://static.yomecontrolo.cl/public/icons/PurpleTicket.png"/>
                            </div>
                            <h6 className='WeAreYMCYouCanTrustBlockTitle'>Validado por la Superintendencia de salud</h6>
                            <div className='WeAreYMCYouCanTrustBlockSubtitle'>El servicio es absolutamente legal. Nuestros médicos cuentan con la validación y supervisión de nuestro país.</div>
                        </div>
                        <div className='WeAreYMCYouCanTrustBlock'>
                            <div className='WeAreYMCYouCanTrustBlockImageContainer'>
                                <img alt="Tiny Doctor Icon" className='WeAreYMCYouCanTrustBlockImage' src="https://static.yomecontrolo.cl/public/icons/PurpleDoctor.png"/>
                            </div>
                            <h6 className='WeAreYMCYouCanTrustBlockTitle'>Obtendrás ordenes emitídas por médicos</h6>
                            <div className='WeAreYMCYouCanTrustBlockSubtitle'>Las órdenes de exámenes emitidas a través de YMC son generadas por médicos inscritos en la Superintendencia de Salud de Chile</div>
                        </div>
                        <div className='WeAreYMCYouCanTrustBlock'>
                            <div className='WeAreYMCYouCanTrustBlockImageContainer'>
                                <img alt="Tiny Credit Card Icon" className='WeAreYMCYouCanTrustBlockImage' style={{height:"auto"}} src="https://static.yomecontrolo.cl/public/icons/PurpleCreditCard.png"/>
                            </div>
                            <h6 className='WeAreYMCYouCanTrustBlockTitle'>Utiliza él método de pago que más te acomode</h6>
                            <div className='WeAreYMCYouCanTrustBlockSubtitle'>Webpay Plus, Mercado Pago o transferencia, ¡Tú decides!</div>
                        </div>
                        <div className='WeAreYMCYouCanTrustBlock'>
                            <div className='WeAreYMCYouCanTrustBlockImageContainer'>
                                <img alt="Tiny Calendar Icon" className='WeAreYMCYouCanTrustBlockImage' src="https://static.yomecontrolo.cl/public/icons/PurpleCalendar.png"/>
                            </div>
                            <h6 className='WeAreYMCYouCanTrustBlockTitle'>No solo ahorras tiempo, sino también decides cuando usarla</h6>
                            <div className='WeAreYMCYouCanTrustBlockSubtitle'>Todas las órdenes de examen emitidas por YMC tienen una vigencia hasta por 60 días.</div>
                        </div>
                        <div className='WeAreYMCYouCanTrustBlock'>
                            <div className='WeAreYMCYouCanTrustBlockImageContainer'>
                                <img alt="Tiny DNA Icon" className='WeAreYMCYouCanTrustBlockImage'  style={{width:"40px"}} src="https://static.yomecontrolo.cl/public/icons/PurpleDNAIconTr.png"/>
                            </div>
                            <h6 className='WeAreYMCYouCanTrustBlockTitle'>Úsala en cualquier laboratorio del país</h6>
                            <div className='WeAreYMCYouCanTrustBlockSubtitle'>Nuestras ordenes de examen pueden ser utilizadas en cualquier laboratorio privado de Chile.</div>
                        </div>
                        <div className='WeAreYMCYouCanTrustBlock'>
                            <div className='WeAreYMCYouCanTrustBlockImageContainer'>
                                <img alt="Tiny Money Cycle Icon" className='WeAreYMCYouCanTrustBlockImage' src="https://static.yomecontrolo.cl/public/icons/PurpleMoney.png"/>
                            </div>
                            <h6 className='WeAreYMCYouCanTrustBlockTitle'>Reembolso de gastos médicos</h6>
                            <div className='WeAreYMCYouCanTrustBlockSubtitle'>Con nuestra orden puedes gestionar el reembolso de tus exámenes.</div>
                        </div>
                    </div>
                </div>
                <div className='WeAreYMCYouCanTrustRightBlock'>
                    <img alt="" className='WeAreYMCYouCanTrustRightBlockImage' src="https://static.yomecontrolo.cl/public/img/home/Lock.png"/>
                </div>
            </div>
        </div>
        <div className='WeAreYMCStartupDiv'>
            {/*<div className='WeAreYMCStartupTeamImage' style={{backgroundImage:"url(" + Team + ")",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
            <img src={Team} alt="Team Image" className='WeAreYMCStartupTeamImage'/>*/}
            <div className='WeAreYMCStartupFirstBlock'>
                <div className='WeAreYMCFirstBlockInnerDiv WeAreYMCStartupDivTitleFlex'>
                    <h1 className='WeAreYMCStartupTitle'>Un equipo enérgico impulsado por la 
                    <span style={{fontWeight:"800",fontStyle:"italic"}}> promoción de la salud, la innovación y tecnologías digitales.</span></h1>
                    <div className='WeAreYMCStartupSubtitle'>Encontrar el bienestar presente y futuro con medicina preventiva a tiempo</div>
                </div>
                <div className='WeAreYMCStartupTeamImagesFlex'>
                    <div className='WeAreYMCStartupTeamTallImageBlock' id="CEOBlock">
                        <div className='WeAreYMCStartupTeamImageContainer' id="CEO">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage" src="https://static.yomecontrolo.cl/public/img/home/TeamJorgeCTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Jorge Caro Díaz</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Médico cirujano<br/>CEO</div>
                        </div>
                    </div>
                    <div className='WeAreYMCStartupTeamShortImageBlock WeAreYMCStartupTeamShortImageBlockReverse' id="Team1">
                        <div className='WeAreYMCStartupTeamImageContainer' id="Team1">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage" src="https://static.yomecontrolo.cl/public/img/home/TeamMacaCTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Macarena Castro</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Enfermera<br/>COO</div>
                        </div>
                    </div>
                    <div className='WeAreYMCStartupTeamTallImageBlock' id="Team2">
                        <div className='WeAreYMCStartupTeamImageContainer' id="Team2">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage WeAreYMCStartupTeamImageSlightOffset" style={{objectPosition:"0rem -1vw"}} src="https://static.yomecontrolo.cl/public/img/home/TeamNicoCTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Nicolás Cenzano</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Ingeniero Civil en Informática<br/>Máster en IA<br/>CTO</div>
                        </div>
                    </div>
                    <div className='WeAreYMCStartupTeamShortImageBlock WeAreYMCStartupTeamShortImageBlockReverse' id="Team3">
                        <div className='WeAreYMCStartupTeamImageContainer' id="Team3">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage" src="https://static.yomecontrolo.cl/public/img/home/TeamPaulaCTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Paula Cuevas</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Magíster en Bioquímica<br/>Gestión de proyectos</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='WeAreYMCStartupSecondBlock'>
                <div className='WeAreYMCStartupTeamImagesFlex'>
                    <div className='WeAreYMCStartupTeamTallImageBlock' id="Team6">
                        <div className='WeAreYMCStartupTeamImageContainer' id="Team6">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage" src="https://static.yomecontrolo.cl/public/img/home/TeamLuisVTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Luis H. Vicencio</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Diseñador de branding y UX/UI<br/>Product manager</div>
                        </div>
                    </div>
                    <div className='WeAreYMCStartupTeamShortImageBlock WeAreYMCStartupTeamShortImageBlockReverse' id="Team4">
                        <div className='WeAreYMCStartupTeamImageContainer' id="Team4">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage" src="https://static.yomecontrolo.cl/public/img/home/TeamMaxNTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Maximiliano Navarrete</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Ingeniero Civil en Informática<br/>Desarrollador fullstack</div>
                        </div>
                    </div>
                    <div className='WeAreYMCStartupTeamTallImageBlock' id="Team5">
                        <div className='WeAreYMCStartupTeamImageContainer' id="Team5">
                            <img alt="Team member" className="WeAreYMCStartupTeamImage" src="https://static.yomecontrolo.cl/public/img/home/TeamBenjaminCTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberTexts">
                            <div className="WeAreYMCStartupTeamMemberName">Benjamín Caro</div>
                            <div className="WeAreYMCStartupTeamMemberSpecialty">Estudiante Ingeniería Civil Industrial<br/>Desarrollador Jr.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="WeAreYMCOurJourney" style={{ backgroundImage: "url('https://static.yomecontrolo.cl/public/img/home/BackgroundOurJourney.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                <div className="WeAreYMCOurJourneyUpTitle">Nuestra Trayectoria</div>
                <h2 className="WeAreYMCOurJourneyTitle">Nuestro camino construyendo soluciones digitales para cuidar la salud presente y futura</h2>
                <div className='WeAreYMCOurJourneyEvents'>
                    <h5 className="WeAreYMCOurJourneyYear">2022</h5>
                    <div className="WeAreYMCOurJourneyFlex">
                        <div className="WeAreYMCOurJourneyEventText" id="text-left">Inicio</div>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/SkyblueRocket.png"/>
                        <h5 className="WeAreYMCOurJourneyDate" id="text-right">Jun. 22</h5>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <h5 className="WeAreYMCOurJourneyDate" id="text-left">Nov. 22</h5>
                        <img className="WeAreYMCOurJourneyIcon WeAreYMCOurJourneyIconBigger" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/SkyblueRocket.png"/>
                        <div className="WeAreYMCOurJourneyEventText" id="text-right">MVP YMC</div>
                    </div>
                    <h4 className="WeAreYMCOurJourneyYear">2023</h4>
                    <div className="WeAreYMCOurJourneyFlex">
                        <div className="WeAreYMCOurJourneyEventText" id="text-left">Inicio Desafío AVANTE 3</div>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/Avante3.png"/>
                        <h5 className="WeAreYMCOurJourneyDate" id="text-right">Feb. 23</h5>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <h5 className="WeAreYMCOurJourneyDate" id="text-left">Mar. 23</h5>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/StartupChile.png"/>
                        <div className="WeAreYMCOurJourneyEventText" id="text-right">Startup Chile BUILD SUP 5 ($12 mill. Free-equity )</div>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <div className="WeAreYMCOurJourneyEventText" id="text-left">Web summit Brasil (Alpha startup programm)</div>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/WebSummit.png"/>
                        <h5 className="WeAreYMCOurJourneyDate" id="text-right">May. 23</h5>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <h5 className="WeAreYMCOurJourneyDate" id="text-left">Jun. 23</h5>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/Avante3.png"/>
                        <div className="WeAreYMCOurJourneyEventText" id="text-right"> 1° lugar Desafío AVANTE 3, ($45 mill. para co-desarrollo solución (ML) junto a la Armada de Chile)</div>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <div className="WeAreYMCOurJourneyEventText" id="text-left">Finalistas Desafío Cáncer (Novartis)</div>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/Novartis.png"/>
                        <h5 className="WeAreYMCOurJourneyDate" id="text-right">Ago. 23</h5>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <h5 className="WeAreYMCOurJourneyDate" id="text-left">Oct. 23</h5>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/YLAI.png"/>
                        <div className="WeAreYMCOurJourneyEventText" id="text-right">Young leaders of the Americas initiave (COO seleccionada)</div>
                    </div>
                    <h3 className="WeAreYMCOurJourneyYear">2024</h3>
                    <div className="WeAreYMCOurJourneyFlex">
                        <div className="WeAreYMCOurJourneyEventText" id="text-left">ACRUX Modelo predictivo y Priorización (ML)</div>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/SkyblueExam.png"/>
                        <h5 className="WeAreYMCOurJourneyDate" id="text-right">Feb. 24</h5>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <h5 className="WeAreYMCOurJourneyDate" id="text-left">Feb. 24</h5>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/YMCLogo.png"/>
                        <div className="WeAreYMCOurJourneyEventText" id="text-right">Lanzamiento nueva plataforma YMC</div>
                    </div>
                    <div className="WeAreYMCOurJourneyFlex">
                        <div className="WeAreYMCOurJourneyEventText" id="text-left">Finalistas en la Hackathon</div>
                        <img className="WeAreYMCOurJourneyIcon" alt="Icono de evento" src="https://static.yomecontrolo.cl/public/icons/Hackaton.png"/>
                        <h5 className="WeAreYMCOurJourneyDate" id="text-right">Ago. 24</h5>
                    </div>
                    {/*
                    <hr className='WeAreYMCOurJourneyLine'></hr>
                    <div className='WeAreYMCOurJourneyExpandFlex'>
                        <img alt="" className='WeAreYMCOurJourneyExpandFlexIcon' src="https://static.yomecontrolo.cl/public/icons/SkyblueArrowDownWhiteCircle.png"/>
                        <div className='WeAreYMCOurJourneyExpandFlexText'>Ver Todo</div>
                    </div>
                    */}
                </div>
            </div>
            <div className='WeAreYMCHackatonTeam'>
                <div className='WeAreYMCFirstBlockInnerDiv WeAreYMCHackatonTeamTitleFlex'>
                    <h1 className='WeAreYMCHackatonTeamTitle'>Equipo Primera Latam OpenAI Hackaton 2024</h1>
                    <div className='WeAreYMCHackatonTeamSubtitle'>Encontrar el bienestar presente y futuro con medicina preventiva a tiempo</div>
                </div>
                <div className='WeAreYMCStartupHackatonTeamImagesFlex'>
                    <div className='WeAreYMCStartupHackatonTeamTallImageBlock' id="Team5">
                        <div className='WeAreYMCStartupHackatonTeamImageContainer2' id="Team5">
                            <img alt="Team member" className='WeAreYMCStartupTeamImage' src="https://static.yomecontrolo.cl/public/img/home/TeamNicolasCTransparent2.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberName2">Nicolás Cenzano</div>
                    </div>
                    <div className='WeAreYMCStartupHackatonTeamShortImageBlock WeAreYMCStartupTeamShortImageBlockReverse' id="Team1">
                        <div className='WeAreYMCStartupHackatonTeamImageContainer2' id="Team1">
                            <img alt="Team member" className='WeAreYMCStartupTeamImage' src="https://static.yomecontrolo.cl/public/img/home/TeamNicolasETransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberName2">Nicolás Escobar</div>
                    </div>
                    <div className='WeAreYMCStartupHackatonTeamTallImageBlock' id="Team2">
                        <div className='WeAreYMCStartupHackatonTeamImageContainer2' id="Team2">
                            <img alt="Team member" className='WeAreYMCStartupTeamImage' src="https://static.yomecontrolo.cl/public/img/home/TeamNicolasFTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberName2">Nicolás Fuentes</div>
                    </div>
                    <div className='WeAreYMCStartupHackatonTeamShortImageBlock WeAreYMCStartupTeamShortImageBlockReverse' id="Team3">
                        <div className='WeAreYMCStartupHackatonTeamImageContainer2' id="Team3">
                            <img alt="Team member" className='WeAreYMCStartupTeamImage' src="https://static.yomecontrolo.cl/public/img/home/TeamCristianVTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberName2">Andrés Hidalgo</div>
                    </div>
                    <div className='WeAreYMCStartupHackatonTeamTallImageBlock' id="Team2">
                        <div className='WeAreYMCStartupHackatonTeamImageContainer2' id="Team2">
                            <img alt="Team member" className='WeAreYMCStartupTeamImage' src="https://static.yomecontrolo.cl/public/img/home/TeamAndresHTransparent.png"/>
                        </div>
                        <div className="WeAreYMCStartupTeamMemberName2">Cristián Villanueva</div>
                    </div>
                </div>
            </div>
        </div>
        {/*
        <div className='WeAreYMCOurSolutionsDiv'>
            <div className='WeAreYMCOurSolutionsDivContainer'>
                <div className='WeAreYMCOurSolutionsUpTitle'>Nuestras Soluciones</div>
                <div className='WeAreYMCOurSolutionsTitle'>SOLUCIONES</div>
                <div className='WeAreYMCOurSolutionsSubtitle'>Tomar el control de tu bienestar actual y futuro,  se encuentra ahora en tu mano, creamos soluciones digitales para prevenir y tratar enfermedades y ayudarte a cuidar conscientemente tu salud y la de tu familia.</div>
                <div className='WeAreYMCOurSolutionsFlexedBlocks'>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Calculadora de Salud</div>
                    </div>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Portal de Pacientes</div>
                    </div>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Exámenes Online</div>
                    </div>
                    <div className='WeAreYMCOurSolutionsSolutionBlock'>
                        <div className='WeAreYMCOurSolutionsSolutionBlockButton'>Medicina Preventiva</div>
                    </div>
                </div>
            </div>
        </div>
        <MakeItEasy/>
        <YouCanTrust/> */}
        <div className='ScrollBackToTopOuter ScrollBackToTopHidden' id="ToTheTop">
            <div className='ScrollBackToTop' onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
            <FontAwesomeIcon 
                icon={faUpLong} 
                className='ScrollBackToTopUpArrow'
            />
            </div>
        </div>
    </div>
  );
}

export default WeAreYMC;