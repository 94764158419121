import React from 'react';
import YMCTrustBlockIcon1 from "../../Photos/YMCTrustBlockIcon1.png"
import YMCTrustBlockIcon2 from "../../Photos/YMCTrustBlockIcon2.png"
import YMCTrustBlockIcon3 from "../../Photos/YMCTrustBlockIcon3.png"
import YMCTrustBlockIcon4 from "../../Photos/YMCTrustBlockIcon4.png"
import YMCTrustBlockIcon5 from "../../Photos/YMCTrustBlockIcon5.png"
import YMCTrustBlockIcon6 from "../../Photos/YMCTrustBlockIcon6.png"

const YouCanTrust = () => {
    return (
      <div className='LandingYouCanTrustDiv'>
        <div className='rectangle'></div>
        <div className='LandingYouCanTrustInnerDiv'>
          {/*<div className='LandingYouCanTrustUpTitle'>Puedes confiar</div>*/}
          <h1 className='LandingYouCanTrustTitle'>Puedes confiar en nosotros</h1>
          <div className='LandingYouCanTrustFlexDiv'>
            <div className='LandingYouCanTrustBlock'>
              <img alt="" className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon1}/>
              <h5 className='LandingYouCanTrustBlockTitle'>Tu eres el dueño de los datos</h5>
              <div className='LandingYouCanTrustBlockSubtitle'>La privacidad y seguridad de la información es parte de nuestros principios fundamentales. Tu información sensible está almacenada de forma cifrada.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img alt="" className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon2}/>
              <h5 className='LandingYouCanTrustBlockTitle'>Obtendrás ordenes emitídas por médicos</h5>
              <div className='LandingYouCanTrustBlockSubtitle'>Las órdenes de exámenes emitidas a través de YMC son generadas por médicos inscritos en la Superintendencia de Salud de Chile.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img alt="" className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon3}/>
              <h5 className='LandingYouCanTrustBlockTitle'>Utiliza él método de pago que más te acomode</h5>
              <div className='LandingYouCanTrustBlockSubtitle'>Webpay Plus, Mercado Pago o transferencia, ¡Tú decides!</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img alt="" className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon4}/>
              <h5 className='LandingYouCanTrustBlockTitle'>No solo ahorras tiempo, sino también decides cuando usarla</h5>
              <div className='LandingYouCanTrustBlockSubtitle'>Todas las órdenes de examen emitidas por YMC tienen una vigencia hasta por 60 días.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img alt="" className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon5}/>
              <h5 className='LandingYouCanTrustBlockTitle'>Úsala en cualquier laboratorio del país</h5>
              <div className='LandingYouCanTrustBlockSubtitle'>Nuestras ordenes de examen pueden ser utilizadas en cualquier laboratorio privado de Chile.</div>
            </div>
            <div className='LandingYouCanTrustBlock'>
              <img alt="" className='LandingYouCanTrustBlockImage' src={YMCTrustBlockIcon6}/>
              <h5 className='LandingYouCanTrustBlockTitle'>Reembolso de gastos médicos</h5>
              <div className='LandingYouCanTrustBlockSubtitle'>Con nuestra orden puedes gestionar el reembolso de tus exámenes.</div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default YouCanTrust;